const BACKEND_URLS = {
    PROD: "https://government-backend.tsleem.com.sa/",
    THECODE: "https://governmentback.thecode.sa/",
    DEV: "https://government-backend-dev.tsleem.com.sa/",
    LOCAL_HOST: "http://127.0.0.1:8000/",
};

const FRONT_URLS = {
    PROD: "https://government.tsleem.com.sa/",
    LOCAL_HOST: "http://localhost:8080/",
    THECODE: "https://government.thecode.sa/",
    DEV: "https://government-dev.tsleem.com.sa/",
};



export const CURRENT_URL_BACK = BACKEND_URLS.PROD
export const CURRENT_URL_FRONT = FRONT_URLS.PROD



const BACKEND_MENA_URLS = {
    PROD: "https://backend.tsleem.com.sa/",
    THECODE: "https://tsleemback.thecode.sa/",
    DEV: "https://backend-dev.tsleem.com.sa/",
    LOCAL_HOST: "http://127.0.0.1:8000/",
};


const FRONT_MENA_URLS = {
    PROD: "https://services.tsleem.com.sa/",
    LOCAL_HOST: "http://localhost:8080/",
    THECODE: "https://tsleem.thecode.sa/",
    DEV: "https://services-dev.tsleem.com.sa/",
    ARAFAT: "https://government-dev.tsleem.com.sa/"
};

export const CURRENT_MENA_URL_BACK = BACKEND_MENA_URLS.PROD
export const CURRENT_MENA_URL_FRONT = FRONT_MENA_URLS.PROD

const BACKEND_Gov_URLS = {
    PROD: "https://government-backend.tsleem.com.sa/",
    THECODE: "https://governmentback.thecode.sa/",
    DEV: "https://government-backend-dev.tsleem.com.sa/",
    LOCAL_HOST: "http://127.0.0.1:8000/",
};

export const CURRENT_Gov_URL_BACK = BACKEND_Gov_URLS.PROD

